<template>
  <div class="m-modal-dislike modal-content">
    <SfModal :visible="isVisible" @close="closeModal">
      <form @submit.prevent="submit" class="form">
        <SfInput
          v-model="formData.name"
          name="firstName"
          :label="$t('First name')"
          :required="true"
          :valid="!$v.formData.name.$error"
          :error-message="
            !$v.formData.name.required
              ? $t('Field is required.')
              : $t('Name must have at least 2 letters.')
          "
          class="form__element"
        />
        <SfInput
          v-model="formData.email"
          name="email"
          :label="$t('Email address')"
          :required="true"
          :valid="!$v.formData.email.$error"
          :error-message="
            !$v.formData.email.required
              ? $t('Field is required.')
              : $t('Please provide valid e-mail address.')
          "
          class="form__element"
        />
        <SfTextarea
          v-model="formData.opinion"
          name="opinion"
          :label="$t('Your Opinion')"
          :required="true"
          :valid="!$v.formData.opinion.$error"
          :error-message="$t('Field is required.')"
          class="form__element"
        />
        <SfButton class="sf-button--full-width form__submit" :disabled="isLoading">
          {{ $t("Add Opinion") }}
        </SfButton>
      </form>
    </SfModal>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import { SfInput, SfTextarea, SfButton } from '@storefront-ui/vue';
import SfModal from '~/theme/components/molecules/SfModal/SfModal.vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'MModalReview',
  components: { SfModal, SfInput, SfButton, SfTextarea },
  data () {
    return {
      formData: {
        name: '',
        email: '',
        review: ''
      },
      isLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {},
      required: true
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    faqId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.user.current;
    },
    storeId () {
      const { storeId } = currentStoreView()
      return storeId
    }
  },
  mounted () {
    this.fillInUserData()
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    clearReviewForm () {
      this.formData.name = '';
      this.formData.email = '';
      this.formData.opinion = '';
      this.$v.$reset();
    },
    fillInUserData () {
      if (this.currentUser) {
        this.formData.name = this.currentUser.firstname;
        this.formData.email = this.currentUser.email;
      }
    },
    async submit () {
      const storeFaqs = JSON.parse(localStorage.getItem('_store_faqs')) || [];
      const matchStoreFaqs = storeFaqs.find(faq => (
        faq.id === this.faqId &&
        faq.product_sku === this.product.sku &&
        faq.type === 'dislike'
      ));

      if (!matchStoreFaqs) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.isLoading = true;
        try {
          const response = await this.$store.dispatch('faq/dislike', {
            dislikeReason: {
              faqId: this.faqId,
              sku: this.product.sku,
              store_id: this.storeId,
              name: this.formData.name,
              email: this.formData.email,
              reason: this.formData.opinion
            }
          });
          storeFaqs.push({ id: this.faqId, product_sku: this.product.sku, type: 'dislike' });
          localStorage.setItem('_store_faqs', JSON.stringify(storeFaqs));
          this.isLoading = false;
          const { code, result } = response;
          const counterElement = document.getElementById(`click-dislike-${this.faqId}`);
          if (code === 200) {
            counterElement.setAttribute('disabled', true);
          }
          counterElement.querySelector('span').innerHTML = code === 200 ? result[0].dislikes : '';
          const notificationConfig = {
            type: code === 200 ? 'success' : 'error',
            message: code === 200 ? this.$t('Thanks for contacting us with your opinion. We will respond to you very soon.') : this.$t('Something went wrong. Try again in a few seconds.'),
            action1: { label: this.$t('OK') }
          };
          this.closeModal();
          document.body.classList.remove('bodylock');
          this.$store.dispatch('notification/spawnNotification', notificationConfig);
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Something went wrong. Try again in a few seconds.'),
            action1: { label: this.$t('OK') }
          });
        }
      }
    }
  },
  validations: {
    formData: {
      name: {
        minLength: minLength(2),
        required
      },
      email: {
        required,
        email
      },
      opinion: {
        required
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep {
    .sf-modal__container {
      --modal-index: 5000;
    }
  }
}
.form {
  width: 100%;
  &__element {
    margin: var(--spacer-base) 0;
  }
  &__submit {
    margin: var(--spacer-xl) 0 0 0;
  }
}
</style>
