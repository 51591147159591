<template>
  <div class="o-ingredients-tab">
    <div v-if="flavours.length || productIsBundle" class="section flavour-selector">
      <SfHeading :title="$t('Choose flavour')" :level="4" />
      <SfComponentSelect
        v-if="!productIsBundle"
        class="flavour-select"
        ref="flavour"
        v-model="selectedFlavour"
      >
        <SfComponentSelectOption
          v-for="option in flavours"
          :key="option.value"
          :value="option"
          class="sort-by__option"
        >
          {{ option.text }}
        </SfComponentSelectOption>
      </SfComponentSelect>

      <SfComponentSelect
        v-if="productIsBundle"
        class="flavour-select"
        ref="flavour"
        v-model="selectedProduct"
      >
        <SfComponentSelectOption
          v-for="option in UniqueProductsInBundle"
          :key="option.name"
          :value="option"
          class="sort-by__option"
        >
          {{ option.name }}
        </SfComponentSelectOption>
      </SfComponentSelect>
    </div>
    <div
      v-if="shouldDisplayTitle"
      class="title section"
    >
      <SfHeading :title="product.name || htmlDecode" :level="4" />
    </div>
    <div v-if="ingredients" class="section ingredients">
      <SfHeading :title="$t('Ingredients')" :level="4" />
      <p v-html="ingredients" />
    </div>
    <div v-if="allergyInformation" class="section allergy-info">
      <SfHeading :title="$t('Allergy information')" :level="4" />
      <p v-html="allergyInformation" />
    </div>
    <div v-if="nutritionTable" class="section nutrition-table">
      <SfHeading :title="$t('Table of nutritions')" :level="4" />
      <div class="nutrition-table__wrapper">
        <SfTable :style="tableCss">
          <SfTableHeading>
            <SfTableHeader
              v-for="header in nutritionTable.headers"
              :key="header"
            >
              {{ header }}
            </SfTableHeader>
          </SfTableHeading>
          <SfTableRow
            v-for="(row, key) in nutritionTable.rows"
            :key="`${row[0]}-${key}`"
          >
            <SfTableData
              v-for="(col, i) in row"
              :key="`${col}-${i}`"
            >
              {{ col }}
            </SfTableData>
          </SfTableRow>
        </SfTable>
      </div>
    </div>
  </div>
</template>

<script>
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfComponentSelect from '@storefront-ui/vue/src/components/molecules/SfComponentSelect/SfComponentSelect.vue';
import SfTable from '@storefront-ui/vue/src/components/organisms/SfTable/SfTable.vue';

export default {
  name: 'OIngredientsTab',
  components: {
    SfHeading,
    SfComponentSelect,
    SfTable
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    flavours: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tableCss: '',
      selectedFlavour: null,
      UniqueProductsInBundle: null,
      selectedProduct: null
    }
  },
  methods: {
    getProductByFlavour (flavourId) {
      return this.product.configurable_children.find(_ => String(_.flavour) === String(flavourId)) || {}
    },
    selectDefaultFlavour () {
      this.selectedFlavour = this.product.flavour
        ? this.flavours.find(_ => _.value === String(this.product.flavour)) || this.flavours[0]
        : this.flavours[0]
    },
    getAllUniqueProductsInBundle (bundle) {
      if (!this.productIsBundle) return
      const optionsArr = bundle.bundle_options.map(option => option.product_links).flat();
      const allProducts = optionsArr.map(option => option.product)
        .filter(product => !!product?.ingredients);
      const uniqueProducts = [...new Map(allProducts.map(product => [product.sku, product])).values()];
      return uniqueProducts
    },
    getProductData () {
      if (this.productIsBundle) {
        this.UniqueProductsInBundle = this.getAllUniqueProductsInBundle(this.product)
        this.selectedProduct = this.UniqueProductsInBundle[0]
      }
      this.selectDefaultFlavour()
    }
  },
  computed: {
    tabProduct () {
      if (['configurable', 'bundle', 'grouped'].includes(this.product.type_id)) {
        return this.selectedFlavour?.product || {}
      }
      return this.product || {}
    },
    ingredients () {
      if (!this.productIsBundle) {
        return this.tabProduct.ingredients
      }
      return this.selectedProduct?.ingredients
    },
    allergyInformation () {
      if (!this.productIsBundle) {
        return this.tabProduct.allergy_info
      }
      return this.selectedProduct?.allergy_info
    },
    nutritionTable () {
      let tbl = this.tabProduct.nutrition_tbl
      if (this.productIsBundle) {
        tbl = this.selectedProduct?.nutrition_tbl
      }
      if (!tbl) return {}
      const table = JSON.parse(tbl)
      const headers = table.tableHeaders.slice(1)
      const rows = table.tableRows.map(row => row.slice(1))
      return {
        headers,
        rows
      }
    },
    productIsBundle () {
      return this.product.type_id === 'bundle';
    },
    shouldDisplayTitle () {
      if (this.productIsBundle) {
        return false
      }
      return !this.flavours.length && (this.ingredients || this.nutritionTable)
    }
  },
  watch: {
    product () {
      this.getProductData()
    }
  },
  mounted () {
    this.getProductData()
    this.$nextTick(() => {
      if (this.nutritionTable.headers) {
        const cols = this.nutritionTable.headers.length
        this.tableCss = `
          --_table-column-width: ${cols}; 
          min-width: 100%;
          width: max-content;
        `
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-ingredients-tab {
  ::v-deep .section {
    @include for-desktop {
      --heading-title-font-size: var(--font-size--base);
      .sf-heading {
        --heading-text-align: left;
      }
    }
    &.flavour-selector {
      --component-select-border-style: solid;
      --component-select-border-color: var(--c-black);
      --component-select-border-width: 0 0 1px;
      .flavour-select {
        @include for-desktop {
          max-width: 15rem;
        }
        .sf-component-select__chevron {
          top: calc(var(--spacer-base) * 1.05);
          right: 0;
        }
      }
    }
    .ingredients, .allergy-info, .title {
      b {
        color: black;
      }
    }
    .nutrition-table {
      &__wrapper {
        overflow-x: scroll;
        max-width: calc(100vw - var(--spacer-lg));
        @include for-desktop {
          overflow-x: auto;
          max-width: 100%;
        }
      }
    }
    p {
      margin: 0 0 var(--spacer-base);
      @include for-desktop {
        margin: var(--spacer-xs) 0 var(--spacer-base);
      }
    }
  }
}

::v-deep {
  .sf-table__heading, .sf-table__row {
    display: table-row;
  }
  .sf-table__header, .sf-table__data {
    font-size: var(--font-size--sm);
    font-weight: var(--font-weight--medium);
    padding: var(--spacer-xs);
    border-bottom: 1px solid var(--c-primary);
    @include for-desktop {
      padding: var(--spacer-sm);
    }
  }
  .sf-table__data {
    font-weight: var(--font-weight--normal);
    border-bottom: 1px solid var(--c-light);
  }
}
</style>
