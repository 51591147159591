<template>
  <AProperty
    class="a-product-attribute"
    :name="label | htmlDecode"
    :value-html="value"
  />
</template>

<script>
import { ProductAttribute } from '@vue-storefront/core/modules/catalog/components/ProductAttribute';
import AProperty from '~/theme/components/atoms/a-property'
export default {
  name: 'AProductAttribute',
  mixins: [ProductAttribute],
  components: {
    AProperty
  }
};
</script>

<style lang="scss" scoped>
.a-product-attribute {
  ::v-deep .sf-property__value{
    font-weight: var(--font-weight--normal) !important;
  }
}
</style>
