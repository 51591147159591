<template>
  <div class="a-rating-pool-bar">
    <div class="a-rating-pool-bar__index">
      <div class="a-rating-pool-bar__index-number">
        {{ index }}
      </div>
      <SfIcon
        size="1rem"
        icon="star"
        :style="{'fill': 'var(--reviews-rating-color)'}"
      />
    </div>
    <div class="a-rating-pool-bar__score-bar">
      <div class="a-rating-pool-bar__scored-bar" :style="{width: scoredBarWitdh}" />
      <div class="a-rating-pool-bar__unscored-bar" :style="{width: unscoredBarWitdh}" />
    </div>
    <div class="a-rating-pool-bar__scored-number">
      {{ scored }}
    </div>
  </div>
</template>

<script>
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
export default {
  name: 'ARatingPoolBar',
  components: {
    SfIcon
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    scored: {
      type: Number,
      require: true,
      default: 5
    },
    maxScore: {
      type: Number,
      require: true,
      default: 5
    }
  },
  data () {
    return {
      promoCode: ''
    };
  },
  computed: {
    percentage () {
      return (this.scored / this.maxScore) * 100
    },
    scoredBarWitdh () {
      return this.percentage + '%'
    },
    unscoredBarWitdh () {
      return (100 - this.percentage) + '%'
    },
    starsColor () {
      return getComputedStyle(document.documentElement).getPropertyValue('--reviews-rating-color')
    }
  }
};
</script>
<style lang="scss" scoped>
.a-rating-pool-bar {
  display: flex;
  width: 200px;
  justify-content: space-between;
  &__index-number {
    width: 1rem;
  }
  &__scored-number {
    width: 1rem;
    text-align: center;
  }
  &__index {
    display: flex;
  }
  &__score-bar {
    display: flex;
    height: 15px;
    width: 150px;
  }
  &__scored-bar {
    background-color: var(--reviews-rating-color);
    height: 100%;
    box-sizing: border-box;
  }
  &__unscored-bar {
    background-color: var(--c-light);
    height: 100%;
  }
  &.active {
    .a-rating-pool-bar__score-bar {
      border: 1px solid var(--reviews-rating-color);
    }
  }
}
</style>
