var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfAccordion',{attrs:{"show-chevron":"","open":_vm.openTab}},[_c('SfAccordionItem',{key:_vm.$t('Description'),attrs:{"header":_vm.$t('Description')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{attrs:{"itemprop":"description"},domProps:{"innerHTML":_vm._s(_vm.product.description)}})]),_vm._v(" "),_c('SfAccordionItem',{key:_vm.$t('Reviews'),attrs:{"header":_vm.$t('Reviews'),"id":"additional_reviews"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(_vm.reviewsCount + ' ' + header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top sf-review-open': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"product-reviews"},[_c('OReviews',{key:_vm.product.parentSku,attrs:{"reviews":_vm.reviews,"product":_vm.product,"total-pages":_vm.totalReviewsPages,"summary":_vm.reviewsSummary},on:{"filter-rating":_vm.filterReviewsByRating}})],1)]),_vm._v(" "),(_vm.additionalInfoLabel)?_c('SfAccordionItem',{key:_vm.additionalInfoLabel,attrs:{"header":_vm.additionalInfoLabel},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"tab__content-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.product.additional_info),expression:"product.additional_info"}],attrs:{"itemprop":"additional-info"},domProps:{"innerHTML":_vm._s(_vm.product.additional_info)}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productMandatoryLabel),expression:"productMandatoryLabel"}],attrs:{"itemprop":"mandatory-label"},domProps:{"innerHTML":_vm._s(_vm.productMandatoryLabel)}})])]):_vm._e(),_vm._v(" "),(_vm.showAttributesInfo)?_c('SfAccordionItem',{key:_vm.$t('Additional Information'),attrs:{"header":_vm.$t('Additional Information')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"tab__content-wrapper"},_vm._l((_vm.attributes),function(attribute,i){return _c('AProductAttribute',{key:i,staticClass:"product__property",attrs:{"product":_vm.product,"attribute":attribute}})}),1)]):_vm._e(),_vm._v(" "),(_vm.showIngredientsTab && !(_vm.product.type_id === 'bundle' && _vm.product.hide_inside_bundle == 1))?_c('SfAccordionItem',{key:_vm.$t('Ingredients'),attrs:{"header":_vm.$t('Ingredients')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"tab__content-wrapper"},[_c('OIngredientsTab',{attrs:{"product":_vm.product,"flavours":_vm.flavours}})],1)]):_vm._e(),_vm._v(" "),(_vm.deliveryTab)?_c('SfAccordionItem',{key:_vm.$t('Delivery'),attrs:{"header":_vm.$t('Delivery')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var header = ref.header;
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(header)+"\n          "),_vm._t("additional-info"),_vm._v(" "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"tab__content-wrapper"},[_c('div',{attrs:{"itemprop":"delivery"},domProps:{"innerHTML":_vm._s(_vm.deliveryTab)}})])]):_vm._e(),_vm._v(" "),(_vm.faqData && _vm.faqData.length > 0)?_c('SfAccordionItem',{key:_vm.$t('faq-asked-questions'),attrs:{"header":"faq-asked-questions"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var isOpen = ref.isOpen;
var accordionClick = ref.accordionClick;
return [_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': isOpen },attrs:{"aria-pressed":isOpen.toString(),"aria-expanded":isOpen.toString()},on:{"click":accordionClick}},[_vm._v("\n          "+_vm._s(_vm.getFaqTabTitle)+"\n          "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--top': isOpen },attrs:{"tabindex":"0"}})],1)]}}],null,false,1593473422)},[_vm._v(" "),_c('div',{staticClass:"tab__content-wrapper"},[_c('MFaqs',{attrs:{"product":_vm.product},on:{"setCurrentSelectFaq":_vm.setSelectedFaq}})],1)]):_vm._e()],1),_vm._v(" "),(_vm.isDislikeSwitcher)?_c('MModalDislike',{staticClass:"sf-dislike-switcher",attrs:{"is-visible":_vm.isDislikeSwitcher,"modal-data":_vm.getModalData('dislike'),"faq-id":_vm.currentSelectFaq,"product":_vm.product},on:{"close":_vm.closeDislikeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }