<template>
  <div class="m-review-bars" v-show="isDataAvailable">
    <ARatingPoolBar
      v-for="(scored, index) in summary.distribution"
      :key="index + 1"
      :index="index + 1"
      :scored="scored"
      :max-score="summary.count"
      @click.native="filterRating(index + 1)"
      :class="{ active: rateFilterValue === index + 1 }"
    />
  </div>
</template>

<script>
import ARatingPoolBar from '~/theme/components/atoms/a-rating-pool-bar'
/*
  TODO use POST Get Product Ratings Summary List from API
 */
export default {
  name: 'MReviewBars',
  components: {
    ARatingPoolBar
  },
  data () {
    return {
      isFilteringEnabled: false,
      currentPage: 1,
      rateFilterValue: null
    }
  },
  props: {
    summary: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isDataAvailable () {
      const distributionSum =
        Object.values(this.summary.distribution).reduce((previous, current) => previous + current)
      return distributionSum !== 0 || this.summary.count === 0
    }
  },
  methods: {
    filterRating (rate) {
      if (this.isFilteringEnabled) {
        this.$emit('filter-rating', rate)
        this.filterReviewsByRating(rate)
      }
    },
    filterReviewsByRating (rate) {
      if (this.rateFilterValue === rate) {
        this.rateFilterValue = null
        return
      }
      this.rateFilterValue = rate
    }
  }
}
</script>

<style lang="scss" scoped>
.m-review-bars {
  display: flex;
  flex-direction: column-reverse;
}
</style>
