<template>
  <div>
    <SfAccordion show-chevron :open="openTab">
      <SfAccordionItem
        :key="$t('Description')"
        :header="$t('Description')"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div itemprop="description" v-html="product.description" />
      </SfAccordionItem>
      <SfAccordionItem
        :key="$t('Reviews')"
        :header="$t('Reviews')"
        id="additional_reviews"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ reviewsCount + ' ' + header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top sf-review-open': isOpen }"
            />
          </SfButton>
        </template>
        <div class="product-reviews">
          <OReviews
            :reviews="reviews"
            :product="product"
            :key="product.parentSku"
            :total-pages="totalReviewsPages"
            :summary="reviewsSummary"
            @filter-rating="filterReviewsByRating"
          />
        </div>
      </SfAccordionItem>
      <SfAccordionItem
        :key="additionalInfoLabel"
        :header="additionalInfoLabel"
        v-if="additionalInfoLabel"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div class="tab__content-wrapper">
          <div v-show="product.additional_info" itemprop="additional-info" v-html="product.additional_info" />
          <div v-show="productMandatoryLabel" itemprop="mandatory-label" v-html="productMandatoryLabel" />
        </div>
      </SfAccordionItem>
      <SfAccordionItem
        :key="$t('Additional Information')"
        :header="$t('Additional Information')"
        v-if="showAttributesInfo"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div class="tab__content-wrapper">
          <AProductAttribute
            v-for="(attribute, i) in attributes"
            :key="i"
            :product="product"
            :attribute="attribute"
            class="product__property"
          />
        </div>
      </SfAccordionItem>
      <SfAccordionItem
        :key="$t('Ingredients')"
        :header="$t('Ingredients')"
        v-if="showIngredientsTab && !(product.type_id === 'bundle' && product.hide_inside_bundle == 1)"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div class="tab__content-wrapper">
          <OIngredientsTab :product="product" :flavours="flavours" />
        </div>
      </SfAccordionItem>
      <SfAccordionItem
        :key="$t('Delivery')"
        :header="$t('Delivery')"
        v-if="deliveryTab"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ header }}
            <slot name="additional-info" />
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div class="tab__content-wrapper">
          <div itemprop="delivery" v-html="deliveryTab" />
        </div>
      </SfAccordionItem>
      <SfAccordionItem
        :key="$t('faq-asked-questions')"
        header="faq-asked-questions"
        v-if="faqData && faqData.length > 0"
      >
        <template #header="{ isOpen, accordionClick }">
          <SfButton
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="sf-button--pure sf-accordion-item__header"
            @click="accordionClick"
          >
            {{ getFaqTabTitle }}
            <SfChevron
              tabindex="0"
              class="sf-accordion-item__chevron"
              :class="{ 'sf-chevron--top': isOpen }"
            />
          </SfButton>
        </template>
        <div class="tab__content-wrapper">
          <MFaqs :product="product" @setCurrentSelectFaq="setSelectedFaq" />
        </div>
      </SfAccordionItem>
    </SfAccordion>
    <MModalDislike
      v-if="isDislikeSwitcher"
      class="sf-dislike-switcher"
      :is-visible="isDislikeSwitcher"
      :modal-data="getModalData('dislike')"
      :faq-id="currentSelectFaq"
      :product="product"
      @close="closeDislikeModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import uniqBy from 'lodash-es/uniqBy';
import flatten from 'lodash-es/flatten';
import { Logger } from '@vue-storefront/core/lib/logger';
import AProductAttribute from '~/theme/components/atoms/a-product-attribute';
import OIngredientsTab from '~/theme/components/organisms/o-ingredients-tab.vue';
import { scrollIntoView } from '~/theme/directives/scrollIntoView';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfChevron from '@storefront-ui/vue/src/components/atoms/SfChevron/SfChevron.vue';
import SfAccordion from '~/theme/components/organisms/SfAccordion/SfAccordion.vue';
import ProductReviewsMixin from '~/theme/mixins/product-reviews.js';
import OReviews from '~/theme/components/organisms/o-reviews';
import MModalDislike from '~/theme/components/molecules/modals/m-modal-dislike';
const MFaqs = () => import(/* webpackChunkName: "m-faqs" */'~/theme/components/molecules/m-faqs');

function hasIngredientsInfo (product) {
  return product?.ingredients || product?.nutrition_tbl
}

function getProductFromLinks (product) {
  return product.product_links.map(_ => _.product)
}

function getProductsFromBundle (product) {
  if (!product.bundle_options) return []
  const productsArrays = product.bundle_options.map(getProductFromLinks)
  return uniqBy(flatten(productsArrays), 'flavour')
}

function getFlavoursFromProducts (products, options) {
  return options
    .map((option) => {
      const flavour = String(option.value)
      const product = products.find(_ => String(_?.flavour) === flavour) || {}
      return {
        ...option,
        product: hasIngredientsInfo(product) ? product : null
      }
    })
    .filter(_ => _.product)
}

export default {
  name: 'MProductAdditionalInfo',
  mixins: [ProductReviewsMixin],
  directives: {
    scrollIntoView
  },
  components: {
    AProductAttribute,
    OIngredientsTab,
    SfButton,
    SfAccordion,
    SfChevron,
    OReviews,
    MFaqs,
    MModalDislike
  },
  data () {
    return {
      openTab: 'faq-asked-questions',
      currentSelectFaq: 0
    };
  },
  props: {
    attributes: {
      type: Array,
      default: () => []
    },
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      isDislikeSwitcher: state => state.ui.dislikeSwitcher
    }),
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks',
      attributesByCode: 'attribute/getAttributeListByCode',
      faqData: 'faq/getFaqData'
    }),
    getFaqTabTitle () {
      return this.$t('Frequently asked questions about {name}', { name: this.product.name })
    },
    additionalInfoLabel () {
      return this.attributesByCode.additional_info?.frontend_label || ''
    },
    mandatoryOptions () {
      return this.attributesByCode.mandatory_label?.options || []
    },
    showAttributesInfo () {
      const showAttributes = false
      return showAttributes && this.attributes.length
    },
    flavourOptions () {
      return this.attributesByCode?.flavour?.options?.filter(_ => _) || []
    },
    productMandatoryLabel () {
      const labelIds = this.product?.mandatory_label || []
      return labelIds
        .map(v => this.mandatoryOptions.find(_ => String(v) === String(_.value))?.label || v)
        .join('. ')
    },
    showAdditionalInfoTab () {
      return this.product.additional_info || this.productMandatoryLabel
    },
    productChildren () {
      if (this.product.type_id === 'configurable') {
        return this.product.configurable_children
      }
      if (this.product.type_id === 'bundle') {
        return getProductsFromBundle(this.product)
      }
      if (this.product.type_id === 'grouped') {
        return this.product.product_links.map(_ => _.product)
      }
      return []
    },
    flavours () {
      const flavours = this.flavourOptions.map(option => ({
        text: option.label,
        value: option.value
      }))
      return getFlavoursFromProducts(this.productChildren, flavours)
    },
    showIngredientsTab () {
      if (this.product.type_id === 'configurable') {
        if (this.product.configurable_children.find(_ => _ === undefined)) {
          Logger.error(`Product's configurable_children contain undefined product`, `SKU:${this.product.sku}`, this.product)()
        }
        return this.product.configurable_children.some(hasIngredientsInfo)
      }
      if (this.product.type_id === 'bundle') {
        if (getProductsFromBundle(this.product).find(_ => _ === undefined)) {
          Logger.error(`Product's bundle_options contain undefined product`, `SKU:${this.product.sku}`, this.product)()
        }
        return getProductsFromBundle(this.product).some(hasIngredientsInfo)
      }
      if (this.product.type_id === 'grouped') {
        return getProductFromLinks(this.product).some(hasIngredientsInfo)
      }
      return hasIngredientsInfo(this.product)
    },
    deliveryTab () {
      const delivery = this.cmsBlocks.find((_) => _.identifier === 'delivery') || {}
      return delivery.content || ''
    }
  },
  methods: {
    setSelectedFaq (value) {
      this.currentSelectFaq = value;
      this.openTab = 'faq-asked-questions';
    },
    onEscapePress () {
      this.closeDislikeModal();
    },
    closeDislikeModal () {
      this.$store.dispatch('ui/toggleDislikeSwitcher', false)
    },
    getFaqList () {
      this.$store.dispatch('faq/list', { filterField: 'product', filterValues: this.product?.parentSku, skipCache: true })
    },
    getModalData (name) {
      return {
        name: name
      }
    },
    openTabEvent (value) {
      this.openTab = ''
      if (value) {
        this.openTab = value
      }
    }
  },
  watch: {
    product: {
      handler (nV) {
        this.reviewsSku = nV.parentSku
      },
      immediate: true
    },
    $route: {
      handler (nV, oV) {
        if (nV.hash === '') {
          this.openTab = ''
        }
        this.getFaqList()
      }
    }
  },
  beforeMount () {
    this.$bus.$on('open-review-tab', this.openTabEvent)
    this.getFaqList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.like-content {
  display: flex;
  .like-button {
    margin: 15px 10px 0 0;
    display: flex;
    font-weight: 600;
    align-items: center;
    font-size: 19px;
    padding: 13px 20px;
    &.rotate {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      margin-right: 10px;
    }
  }
}
.tab__content-wrapper ::v-deep {
  padding-top: 5rem;
  margin-top: -5rem;
  max-width: calc(100vw - var(--spacer-lg));
  p img {
    max-width: 100%;
  }
}
.m-product-additional-info {
  width: 100%;
  --tabs-title-z-index: 0;
  ::v-deep {
    .sf-tabs__content__tab {
      padding-top: var(--spacer-sm);
    }
    .sf-tabs {
      &__title {
        --tabs-title-font-family: var(--font-family--secondary);
        --tabs-title-font-weight: var(--font-weight--semibold);
        --tabs-title-font-size: calc(var(--font-size--lg) * 1.25);
        border-bottom-width: 1px;
      }
    }
  }
}
.product {
  &__tabs {
    margin: var(--spacer-lg) auto var(--spacer-lg);
    @include for-desktop {
      margin-top: var(--spacer-xl);
      --tabs-content-tab-padding: var(--spacer-base) 0 0 0;
    }
  }
  &__property {
    margin: var(--spacer-base) 0;
  }
}
[itemprop="description"] {
  max-width: calc(100% - var(--spacer-xl));
}
[itemprop="description"] > *:first-child {
  margin-top: 0;
}
</style>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

#product {
  .additional-info {
    @include for-mobile {
      padding: 0 var(--spacer-sm) var(--spacer-sm) var(--spacer-sm);
    }
  }
  .sf-accordion-item {
    border-bottom: 1px solid var(--c-light);
    &__header {
      .sf-chevron {
        transform: rotate(0);
        width: 22px;
        height: 22px;
        position: relative;
        &__bar {
          height: 22px;
          width: 2px;
          background: var(--c-true-black);
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          transform: none;
          &:after {
            background: transparent;
          }
        }
        .sf-chevron__bar--right {
          height: 2px;
          width: 22px;
        }
      }
      &.is-open {
        .sf-chevron {
          transform: rotate(45deg);
        }
      }
      + div {
        height: auto !important;
      }
    }
    &__content {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
    > .sf-button {
      font-family: var(--font-family--primary);
      font-weight: var(--font-weight--medium);
      padding: 1.2rem 0;
      font-size: 18px;
      @include for-mobile {
        text-align: left;
      }
    }
    p {
      margin-top: 0;
    }
  }
}
</style>
