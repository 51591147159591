<template>
  <div id="o-reviews" class="product-reviews">
    <div>
      <SfHeading
        :title="reviewsCountMessage"
        :level="3"
        class="sf-heading--center"
      />
      <div class="reviews-header">
        <div class="reviews-header__score-info">
          <div class="reviews-header__score-wrapper">
            <div class="product-rating-score">
              {{ summary.rating.toFixed(1) }}
            </div>
            <AProductRating
              v-show="summary.rating !== undefined && summary.rating !== null"
              :score="summary.rating"
              :reviews-count="summary.count"
              class="a-product-rating"
            />
          </div>
          <MReviewBars v-if="displayDistribution" :summary="summary" @filter-rating="filterRating" />
        </div>

        <div class="reviews-header__right-panel">
          <SfButton @click="handleOpenReviewModal" v-if="enableWriteReviews">
            {{ $t('Write a review') }}
          </SfButton>
          <SfSelect
            v-show="displaySortingSelector"
            v-model="pickedSortingMethod"
            name="sort-by"
            :label="$t('Sort By')"
            class="form__element"
          >
            <SfSelectOption
              v-for="sortBy in sortingMethods"
              :key="sortBy"
              :value="sortBy"
            >
              {{ $t(sortBy) }}
            </SfSelectOption>
          </SfSelect>
        </div>
      </div>
      <MReviewList
        :reviews="reviews"
        :product="product"
        :total-pages="totalPages"
        :sorting-method="pickedSortingMethod"
        :loading="areReviewsLoading"
        :error="reviewsErrorMessage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import config from 'config';
import { mapActions } from 'vuex';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfSelect from '@storefront-ui/vue/src/components/molecules/SfSelect/SfSelect.vue';

import { isServer } from '@vue-storefront/core/helpers';

import { ModalList } from '~/theme/store/ui/modals';
import MReviewList from '~/theme/components/molecules/m-review-list';
import MReviewBars from '~/theme/components/molecules/m-review-bars';
import AProductRating from '~/theme/components/atoms/a-product-rating';

export default {
  name: 'OReviews',
  components: {
    SfButton,
    AProductRating,
    MReviewList,
    SfHeading,
    MReviewBars,
    SfSelect
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    },
    product: {
      type: Object,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    summary: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      sortingMethods: config.stampedIo.sortingMethods || [],
      pickedSortingMethod: config.stampedIo.defaultSortingMethod
    }
  },
  created () {
    if (isServer) return
    this.getReviewsSummary({ skus: [this.product.parentSku] })
    this.listReviews({ sku: this.product.parentSku, page: 1, sortBy: 'photos' })
  },
  computed: {
    displayDistribution () {
      return config.stampedIo.displayDistribution
    },
    displaySortingSelector () {
      return config.stampedIo.enableSorting
    },
    reviewsCount () {
      return this.$store.state['stamped-io'].totalReviews[this.product.parentSku] || 0
    },
    reviewsCountMessage () {
      return this.$t('{count} Reviews', { count: this.summary.count })
    },
    score () {
      const allScore = this.reviews.reduce((sum, rev) => (sum + (rev?.rating || 0)), 0)
      return allScore ? allScore / this.reviewsCount : allScore
    },
    areReviewsLoading () {
      return this.$store.state['stamped-io'].isLoading
    },
    reviewsErrorMessage () {
      return this.$store.state['stamped-io'].errorMessage
    },
    enableWriteReviews () {
      return config.stampedIo.enableReviewsWriting
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    ...mapActions('stamped-io', {
      listReviews: 'list',
      getReviewsSummary: 'getSummary'
    }),
    handleOpenReviewModal () {
      this.openModal({
        name: ModalList.Review,
        payload: {
          productId: this.product.id,
          productSku: this.product.parentSku,
          productName: this.product.name,
          productImage: this.product.image
        }
      })
    },
    filterRating (rate) {
      this.$emit('filter-rating', rate)
    },
    async handlePageChange ({ page, options, sortingMethod, currentPage }) {
      if (currentPage === page && !options.force) return
      this.listReviews({ sku: this.product.parentSku, page, sortBy: sortingMethod, forceFetch: options.force })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.product-reviews {
  margin: var(--spacer-sm);
}

.reviews-header__score-info {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.reviews-header__score-wrapper {
  display: flex;
  align-items: center;

}
.reviews-header {
  display: flex;
  justify-content: space-between;
  margin: var(--spacer-sm) 0;
  @include for-mobile {
    flex-wrap: wrap;
  }
}
.reviews-header__right-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  order: 3;

  @include for-mobile {
    margin: 10px;
    width: 100%;
  }
}

.product-rating-score {
  font-size: 2.5rem;
  font-weight: bold;
  margin-right: 20px;
}

.a-product-rating {
  align-self: flex-end;
  margin-right: 0;
  @include for-mobile {
    margin-right: 1rem;
  }
}

.reviews-header-wrapper {
  display: flex;
}
</style>

<style lang="scss">
.reviews-header {
  .sf-button {
    &:empty {
      display: none;
    }
  }
  .product__rating {
    align-items: flex-start;
  }
  .product__count {
    font-size: 14px;
  }
}
</style>
