import { render, staticRenderFns } from "./m-product-additional-info.vue?vue&type=template&id=438afc16&scoped=true&"
import script from "./m-product-additional-info.vue?vue&type=script&lang=js&"
export * from "./m-product-additional-info.vue?vue&type=script&lang=js&"
import style0 from "./m-product-additional-info.vue?vue&type=style&index=0&id=438afc16&lang=scss&scoped=true&"
import style1 from "./m-product-additional-info.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438afc16",
  null
  
)

export default component.exports